$primary-color: #ffffff;
$secondary-color:#171717;

@font-face {
  font-family: 'PTSans-bold';
  src: url('./assets/fonts/PTSans-Bold.ttf');
}

@font-face {
  font-family: 'PTSans-Regular';
  src: url('./assets/fonts/PTSans-Regular.ttf');
}

input,
textarea{
  font-family: 'Helvetica Neue', 'sans-serif';
  color: $secondary-color;
}

html{
  font-family:'PTSans-Regular','sans-serif';
  font-size: 1rem;
}

body {
  margin: 0;
  color:$secondary-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3{
    text-transform: uppercase;
    letter-spacing: -3px;
    font-weight: bold;
}
a, a.link-secondary{
  color: $secondary-color!important;
  letter-spacing: 1.2px;
}

img.client-logo {
  max-width: 7vw;
  filter:saturate(0%);
}

.gray-to-white{
  color:#c6c6c6;
}

/*
* home animation 
*/

.rw-sentence{
  text-transform: unset;
  font-weight: bold!important;
}

.rw-sentence>.rw-words.rw-words-1>span {
  font-size:3rem;
  font-weight: bold;
}

.rw-wrapper{
width: 80vw;
position: relative;
margin: 20px auto 0 auto;
padding: 20px;
}
.rw-sentence{
margin: 0;
text-align: left;
text-shadow: 1px 1px 1px rgba(255,255,255,0.8);
font-size:3rem;
}
.rw-sentence span{
color: #444;
font-size: 100%;
font-weight: bold;
}
.rw-words{
display: block;
}
.rw-words-1 span{
position: absolute;
opacity: 0;
overflow: hidden;
color: #337f04;
-webkit-animation: rotateWord 23s linear infinite 0s;
-moz-animation: rotateWord 23s linear infinite 0s;
-o-animation: rotateWord 23s linear infinite 0s;
-ms-animation: rotateWord 23s linear infinite 0s;
animation: rotateWord 23s linear infinite 0s;
}
.rw-words-1 span:nth-child(2) { 
-webkit-animation-delay: 3s; 
-moz-animation-delay: 3s; 
-o-animation-delay: 3s; 
-ms-animation-delay: 3s; 
animation-delay: 3s; 
color: #ff0000;
}
.rw-words-1 span:nth-child(3) { 
  -webkit-animation-delay: 6s; 
-moz-animation-delay: 6s; 
-o-animation-delay: 6s; 
-ms-animation-delay: 6s; 
animation-delay: 6s; 
color: #448AFF;	
}
.rw-words-1 span:nth-child(4) { 
  -webkit-animation-delay: 9s;
-moz-animation-delay: 9s; 
-o-animation-delay: 9s; 
-ms-animation-delay: 9s; 
animation-delay: 9s; 
color: #D50000;
}
.rw-words-1 span:nth-child(5) { 
  -webkit-animation-delay: 12s; 
-moz-animation-delay: 12s; 
-o-animation-delay: 12s; 
-ms-animation-delay: 12s; 
animation-delay: 12s; 
color: #3F51B5;
}
.rw-words-1 span:nth-child(6) { 
  -webkit-animation-delay: 15s;
-moz-animation-delay: 15s; 
-o-animation-delay: 15s; 
-ms-animation-delay: 15s; 
animation-delay: 15s; 
color: #9b6b9d;
}
.rw-words-1 span:nth-child(7) { 
-webkit-animation-delay: 18s;
-moz-animation-delay: 18s; 
-o-animation-delay: 18s; 
-ms-animation-delay: 18s; 
animation-delay: 18s; 
color: #172170;
}

.rw-words-1 span:nth-child(8) { 
  -webkit-animation-delay: 21s; 
-moz-animation-delay: 21s; 
-o-animation-delay: 21s; 
-ms-animation-delay: 21s; 
animation-delay: 21s; 
color: #448AFF;	
}

@-webkit-keyframes rotateWord {
  0% { opacity: 0; }
  2% { opacity: 0; -webkit-transform: translateY(-30px); }
5% { opacity: 1; -webkit-transform: translateY(0px);}
  17% { opacity: 1; -webkit-transform: translateY(0px); }
20% { opacity: 0; -webkit-transform: translateY(30px); }
80% { opacity: 0; }
  100% { opacity: 0; }
}
@-moz-keyframes rotateWord {
  0% { opacity: 0; }
  2% { opacity: 0; -moz-transform: translateY(-30px); }
5% { opacity: 1; -moz-transform: translateY(0px);}
  17% { opacity: 1; -moz-transform: translateY(0px); }
20% { opacity: 0; -moz-transform: translateY(30px); }
80% { opacity: 0; }
  100% { opacity: 0; }
}
@-o-keyframes rotateWord {
  0% { opacity: 0; }
  2% { opacity: 0; -o-transform: translateY(-30px); }
5% { opacity: 1; -o-transform: translateY(0px);}
  17% { opacity: 1; -o-transform: translateY(0px); }
20% { opacity: 0; -o-transform: translateY(30px); }
80% { opacity: 0; }
  100% { opacity: 0; }
}
@-ms-keyframes rotateWord {
  0% { opacity: 0; }
  2% { opacity: 0; -ms-transform: translateY(-30px); }
5% { opacity: 1; -ms-transform: translateY(0px);}
  17% { opacity: 1; -ms-transform: translateY(0px); }
20% { opacity: 0; -ms-transform: translateY(30px); }
80% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes rotateWord {
  0% { opacity: 0; }
  2% { opacity: 0; transform: translateY(-30px); }
5% { opacity: 1; transform: translateY(0px);}
  17% { opacity: 1; transform: translateY(0px); }
20% { opacity: 0; transform: translateY(30px); }
80% { opacity: 0; }
  100% { opacity: 0; }
}
@media screen and (max-width: 768px){
.rw-wrapper {width:90%;}
.rw-sentence { font-size: 22px; letter-spacing: -1px;}
.rw-sentence > .rw-words.rw-words-1 > span{ font-size: 22px;}
.rw-words {
  display: block;
  text-indent: 0px;
}
img.client-logo {
  max-width: 25vw;
}
}
@media screen and (max-width: 320px){
.rw-sentence { font-size: 9px; }
}

.rw-wrapper>h5 {
margin: 0;
padding:0;
top:0;
position: relative;
line-height: 1em;
}

.clients-section{
  margin-top:75px;
}

/*
* home animation end
*/